/* eslint-disable react/no-unknown-property */
const MainAppIcon = () => (
  <svg
    width="108"
    height="66"
    viewBox="0 0 108 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_1943_9643" fill="white">
      <rect x="11.3145" width="92.4" height="60.3429" rx="1" />
    </mask>
    <rect
      x="11.3145"
      width="92.4"
      height="60.3429"
      rx="1"
      stroke="currentColor"
      stroke-width="4"
      mask="url(#path-1-inside-1_1943_9643)"
    />
    <rect
      x="54.6855"
      y="9.42773"
      width="7.54286"
      height="35.8286"
      transform="rotate(90 54.6855 9.42773)"
      fill="#D9D9D9"
    />
    <rect
      x="75.9297"
      y="15.5859"
      width="31.0571"
      height="49.9143"
      fill="white"
      stroke="currentColor"
    />
    <rect
      x="35.8281"
      y="18.8574"
      width="7.54286"
      height="16.9714"
      transform="rotate(90 35.8281 18.8574)"
      fill="#D9D9D9"
    />
    <rect
      x="90.5156"
      y="56.5723"
      width="7.54286"
      height="28.2857"
      transform="rotate(180 90.5156 56.5723)"
      fill="#D9D9D9"
    />
    <path
      d="M22.6289 41.4652C22.6289 41.4652 36.26 51.4464 44.8534 48.4759C51.3979 46.2137 49.7705 36.4828 56.7065 35.8567C59.6435 35.5916 64.1146 37.2588 64.1146 37.2588"
      stroke="#D9D9D9"
    />
    <rect
      x="101.828"
      y="56.5723"
      width="7.54286"
      height="16.9714"
      transform="rotate(180 101.828 56.5723)"
      fill="#D9D9D9"
    />
    <rect
      x="0.5"
      y="60.8438"
      width="74.4286"
      height="4.65714"
      fill="currentColor"
      stroke="currentColor"
    />
    <rect
      x="43.8425"
      y="60.8152"
      width="6.6"
      height="0.942857"
      fill="white"
      stroke="white"
      stroke-width="0.942857"
    />
    <rect
      x="76.5"
      y="60.5"
      width="30"
      height="5"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);

export default MainAppIcon;
